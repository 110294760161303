<!--退件回寄大陆列表页面-->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('回寄记录')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('回寄单号')" v-model="query.backCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('退件分包号')" v-model="query.backPkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('国内物流单号')" v-model="query.backExpCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar_demo3" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('expBack:create')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()" v-if="hasPermission('expBack:update')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="inputSubPkgVisiable = true" v-if="hasPermission('expBack:printSubPkg')">
          <i class="el-icon-printer" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('打印分包单')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="uploadExpBack()" v-if="hasPermission('expBack:import')">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导入')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getExpBacks(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('回寄记录下载'),sheetName: 'Sheet1' })" >
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="expBacks" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox"  min-width="55" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <!-- 打包时间、分包号、物流单号、回寄单号、总票数、分包数、创建人 -->
      <vxe-table-column  field="id" :title="msg('ID')" min-width="70" key="id" :fixed="isMinScreen?null: 'left'"
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column  field="created" :title="msg('创建时间')" key="created" min-width="120"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column  field="backPkgCode" :title="msg('分包号')" key="backPkgCode" min-width="120" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column  field="backExpCode" :title="msg('物流单号')" key="backExpCode" min-width="120" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column  field="backCode" :title="msg('回寄单号')" key="backCode" min-width="150" :show-overflow="true"
         sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column  field="creatName" :title="msg('创建人')" key="creatName" min-width="80"
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" @opened="handleShelfOpen" width="800px">
    <exp-back-edit :id="currId" :expBack="currBack" v-on:closeDg="closeEditDialog" ref="shelfEdit"/>
  </el-dialog>

  <el-dialog :title="msg('导入回寄信息')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen"  width="600px" 
    @opened="hendleOpenedImport()" @closed="getExpBacks" >
    <exp-back-upload ref="ShelfImportRef"/>
  </el-dialog>

  <el-dialog :title="msg('输入分包信息？')" :visible.sync="inputSubPkgVisiable" :fullscreen="isMinScreen"  width="400px">
      <div style="text-align:center; ">
        <el-input :placeholder="msg('请输入回寄单号')" v-model="backCode">
          <template slot="prepend">{{msg('回寄单号')}}</template>
        </el-input>
        <el-input :placeholder="msg('请输入分包数量')" v-model="codeQty"  style="margin-top:15px; margin-bottom:15px;">
          <template slot="prepend">{{msg('分包数量')}}</template>
        </el-input>
        <el-button type="primary" size="mini"  @click="doPrint()"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="inputSubPkgVisiable=false">{{msg('取消')}}</el-button>
      </div>
  </el-dialog> 


  <el-dialog :title="msg('打印分包')" :visible.sync="dialogSubPkgVisible" :fullscreen="isMinScreen"  width="600px" @opened="openPrintPage()">
    <vue-easy-print tableShow ref="easyPrint">
      <template>
        <div class="print-body">
          <div class="custs" v-if="subPkgs">
              <div class="cust" v-for="(cust, idx) in subPkgs" :key="idx" 
                      style="page-break-before: always;">
                  <div class="date">{{date.getFullYear() + '-' + (date.getMonth()+1) +'-'+ date.getDate()}}</div>
                  <div class="area1">
                      <img :src="'data:image/png;base64,'+cust.backCodeBar"/>
                      <div>{{cust.backCode}}</div>
                  </div>

                  <div class="area2">
                      <p>{{cust.ownerName}}</p>
                      <img :src="'data:image/png;base64,'+cust.pkgCodeBar"/>
                      <div>{{cust.backPkgCode}}</div>
                  </div>
              </div>
          </div>
        </div>
      </template>
    </vue-easy-print>
  </el-dialog>

</div>
</template>

<script>
// @ is an alias to /src
import ExpBackEdit from "./ExpBackEdit.vue";
import ExpBackUpload from "./ExpBackUpload.vue";
import vueEasyPrint from "vue-easy-print";

export default {
  name: 'ExpBack',
  components: {
    ExpBackEdit: ExpBackEdit, 
    ExpBackUpload: ExpBackUpload, 
    vueEasyPrint: vueEasyPrint 
  },
  mounted:function(){
    this.getExpBacks();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          backCode:null,
          backPkgCode:null,
          backExpCode:null,
        },
        currentPage: 1,   //分页的当前页
        expBacks: [],   //表格数据列表
        dglogTitle: this.msg('编辑信息'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        currBack:null,
        isAdd : false,
        dialogImportVisible:false,
        dialogSubPkgVisible: false,
        inputSubPkgVisiable: false,

        date: new Date(),
        subPkgs: [],
        backCode: null,
        codeQty: 1,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 320;
      }
    }
  },
  methods: {
    getExpBacks: function(){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      this.axios.post(this.$kit.api.expBackList, param).then(function (result) {
        if(result.status){
          if(_this.expBacks.length>0)
            _this.expBacks.splice(0, _this.expBacks.length);
          
          _this.total = result.data.recordsTotal;
          var datas = result.data.data;

          for(var i=0; i<datas.length; i++){
            var item = datas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }

            _this.expBacks.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getExpBacks();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getExpBacks();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getExpBacks();
    },
    add(){  //添加
      this.isAdd = true;
      this.currId = null;
      this.currBack = {
        backPkgCode: null,
        backExpCode: null,
        backCode: null,
      };
      this.dglogTitle = this.msg("添加新物流上架信息");
     
      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      _this.currBack = selectes[0];
      delete _this.currBack.created;
      _this.currId = _this.currBack.id;
      _this.dglogTitle = this.msg("编辑回寄信息");
      _this.isAdd = false;
      _this.dialogTableVisible = true;

    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExpBacks();
      }
      this.dialogTableVisible = false;
    },
    hendleOpenedImport(){
      this.$nextTick(() => {
        this.$refs.ShelfImportRef.initForm();
      });
    },
    handleShelfOpen(){
      this.$nextTick(() => {
      });
    },
    uploadExpBack(){
        this.dialogImportVisible = true;
    },
    
    doPrint(){
      
      if(!this.backCode){
        this.$message(this.msg("回寄单号不能为空"));
        return;
      }

      this.axios.get(this.$kit.api.expBackPrintSubPkg+"?backCode="+this.backCode+"&qty="+this.codeQty)
        .then( (result) => {
          if(result.status){
            
            this.subPkgs = result.data;
            this.inputSubPkgVisiable = false;
            this.dialogSubPkgVisible = true;
          }else{
            this.$message(result.msg);
          }
          
      }).catch(function (error) {
        console.error(error);
      });

    },
    openPrintPage(){
       setTimeout(()=>{
          this.$refs.easyPrint.print();
      }, 5000);
    }
  }
}
</script>

<style class="scss" scoped>

.print-body{
  text-align:center; 
}

.cust{
  margin:0px auto; padding:10px;  text-align:left;width: 350px; 
}

.print-body .date{
  font-size:20px;  margin-bottom:5px;
}
.area1{text-align:center; border-bottom: 1px solid #000000; padding-bottom: 15px;}
.area2{
  text-align:center; border-bottom: dashed 2px black; padding-bottom:15px;
}
.area2 p{margin:0px; padding-top:10px; padding-bottom:5px; font-size:24px; font-weight: bold;
  
}
</style>
